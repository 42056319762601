<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <section id="terms" class="mb-8">
          <jublia-h3 class="DINBold text-jublia-bluest  uppercase">{{ $t('terms.title') }}</jublia-h3>
          <jublia-p class="text DINRegular mt-6" v-html="$t('terms.text')" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import JubliaHeader from '@/components/header/jublia-header'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaH3 from '@/components/text/jublia-h3'
import JubliaP from '@/components/text/jublia-p'

export default {
  components: {
     'jublia-header':JubliaHeader,
    'jublia-h1 ': JubliaH1,
    'jublia-h3 ': JubliaH3,
    'jublia-p':JubliaP
  }
}
</script>
